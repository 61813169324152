import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';
import circle from './image/checkcircle.svg';
import Button, { VARIANT } from '@components/Button/Button.js';

// import { parseString } from '@helpers';
import style from './CardData.module.scss';
import Card from '../Card/Card';
import { navigate } from 'gatsby';

const CardData = ({ primary, items }) => {
  // const [colorized, setColorized] = useState(false);
  // console.log('card items', items)
  // console.log('card primary', primary)

  const { buttonlink, buttontext } = primary;

  // console.log('buttonlink', buttonlink.url);

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   navigate(`${buttonlink.url}`);
  // }


  // const { bottom_clarification, title, subtitle, plan_benefits } = items;

  return (
    <div className={style.container}>
      <div
        // className={classnames({
        //   [style.cardsmobile]: isMobile,
        //   [style.cards]: !isMobile,
        // })}

        className={style.cards}
      >
        {/* {console.log('fields', fields)} */}

        {items?.map((item, index) => (
          <Card
            key={index}
            title={item.title}
            subtitle={item.subtitle}
            // enterpriseCondition={primary.enterprisecondition.text}
            // enterpriseButtonText={primary.enterprisebuttontext.text}
            // enterpriseButtonLink={primary.enterprisebuttonlink.url}
            bottomClarification={item.bottom_clarification}
            planBenefits={item.plan_benefits}
            imgUrl={item.img?.url}
            checkFeaturesOnCard={item.check_features_on_card}
          // toggleBussinessCards={toggleBussinessCards}
          />
        ))}

      </div>
      {/* <div className={style.buttonWrapper}>
        <RichText render={buttontext.richText} />
      </div> */}
      {/* <div className={style.buttonWrapper}>
        <Button variant={VARIANT.PRIMARY} to={`${buttonlink.url}`}>
          <RichText render={buttontext.richText} />
        </Button>
      </div> */}
    </div>
  );
};

// Card.defaultProps = {
//   isEnterprise: false,
// };

CardData.propTypes = {
  primary: PropTypes.arrayOf.isRequired,
  items: PropTypes.arrayOf.isRequired,
};

export default CardData;
