import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBreakpoints } from '@hooks';
import { globalHistory as history } from '@reach/router';
import FaqSemanticMarkup from '@components/FaqSemanticMarkup/FaqSemanticMarkup';
import BreadcrumbsSemanticMarkup from '@components/BreadcrumbsMarkup/BreadcrumbsMarkup';
import style from './PricingPage.module.scss';
import Hero from './components/Hero';
import TariffPlans from './components/TariffPlans';
import PlansFeatures from './components/PlansFeatures/PlansFeatures';
import Partners from './components/Partners/Partners';
import Questions from './components/Questions';

import TalkToExpert from './components/TariffPlans/components/TalkToExpert';
import Card from './components/CardData/CardData';
import CardData from './components/CardData/CardData';
import Comparison from './components/Comparison';
import BenefitTable from './components/BenefitTable';
import Legislationsupport from './components/Legislationsupport';
import BannerCustomization from './components/BannerCustomization';
import DSAandReporting from './components/DSAandReporting';
import Policies from './components/Policies';
import Support from './components/Support';
import Button, { VARIANT } from '@components/Button/Button.js';
import { RichText } from 'prismic-reactjs';

// import TalkToExpert from './components/TalkToExpert/TalkToExpert';

const CARDS_LIST_WIDTH = 920;

const PricingPage = ({ content, canonical, metatitle }) => {
  const [isBarShowing, setIsBarShowing] = useState(false);
  const [cardNumber, setCardNumber] = useState(0);
  const [activepoint, setActivePoint] = useState(0);
  const [itemsSlider, setItemSlider] = useState([]);
  const [itemsSliderFull, setItemSliderFull] = useState([]);
  const [businessToggle, setBusinessToggle] = useState([]);
  const [businessToggleFull, setBusinessToggleFull] = useState([]);
  const { location } = history;

  const { width } = useBreakpoints();
  const myPackagesRef = useRef(null);

  const showBar = () => setIsBarShowing(true);
  const hideBar = () => setIsBarShowing(false);

  useEffect(() => {
    setCardNumber(location.hash.includes('enterprise') ? 2 : 3);
  }, [location]);

  const setActive = (value) => {
    const hiddenWidth = CARDS_LIST_WIDTH - width;
    const hiddenWidthPerCard = hiddenWidth / cardNumber;
    const active =
      Math.ceil(value / hiddenWidthPerCard) > cardNumber - 1
        ? cardNumber - 1
        : Math.ceil(value / hiddenWidthPerCard);
    setActivePoint(active);
    myPackagesRef.current.scrollLeft = value;
  };

  const setActiveOnClick = (index) => {
    setCardNumber(location.hash.includes('enterprise') ? 2 : 3);

    setActivePoint(index);
    const hiddenWidth = CARDS_LIST_WIDTH - width;
    const hiddenWidthPerCard = hiddenWidth / cardNumber;
    const leftScroll =
      index === cardNumber - 1 ? hiddenWidth : index * hiddenWidthPerCard;
    myPackagesRef.current.scrollLeft = leftScroll;
  };

  const questions = content.filter((item) => item.slice_type === 'questions');
  const faqLists = questions.map((element) => element.items);
  const faqList = faqLists.flat();

  useEffect(() => {
    location.hash.includes('enterprise') && setActivePoint(0);
  }, [location]);

  useEffect(() => {
    if (content && content.length) {
      content.map((item) => {
        if (item.slice_type === 'widget_slider_with_plans') {
          setItemSlider(item.items);
        }
        if (item.slice_type === 'bussiness_enterprise_toggle') {
          setBusinessToggle(item.items);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (itemsSlider && itemsSlider.length) {
      setItemSliderFull(itemsSlider);
    }
    if (businessToggle && businessToggle.length) {
      setBusinessToggleFull(businessToggle);
    }
  }, [itemsSlider, businessToggle]);

  // console.log('content', content);

  const sections = content.map((item, index) => {
    switch (item.slice_type) {
      case 'hero_business':
        return !location?.hash?.includes('enterprise') ? (
          <>{<Hero key={index} {...item} sliceType="hero_business" />}</>
        ) : null;
      case 'hero_enterprise':
        return !location?.hash?.includes('enterprise') ? (
          <>{<Hero key={index} {...item} sliceType="hero_enterprise" />}</>
        ) : null;
      case 'tariffplans':
        return (
          <>
            <TariffPlans
              key={index}
              {...item}
              isBarShowing={isBarShowing}
              showBar={showBar}
              hideBar={hideBar}
              activepoint={activepoint}
              scrollableRef={myPackagesRef}
              setActive={setActive}
              setActiveOnClick={setActiveOnClick}
              sliderPlans={itemsSliderFull}
              businessToggle={businessToggleFull}
            />
          </>
        );
      // case 'talk_expert':
      //   return (
      //     <>
      //       <TalkToExpert key={index} {...item} />
      //     </>
      //   );

      case "new_tariffplan":
        return <CardData key={index} {...item} />

      default:
        return null;
    }
  });

  const anotherSections = content.map((item, index) => {
    switch (item.slice_type) {
      case 'partners':
        return <Partners key={index} {...item} />;

      case 'questions':
        return <Questions key={index} {...item} />;

      default:
        return null;
    }
  });

  const featureComnparisonHeader = content.map((item, index) => {
    switch (item.slice_type) {
      case "featurecomparison":
        return <Comparison key={index} {...item} />;


      default:
        return null;
    }
  });
  const featureComnparison = content.map((item, index) => {
    switch (item.slice_type) {
      case "legislationsupport":
        return <Legislationsupport key={index} {...item} />;
      case "bannercustomization":
        return <BannerCustomization key={index} {...item} />;
      case "dsar_and_reporting":
        return <DSAandReporting key={index} {...item} />;
      case "policies":
        return <Policies key={index} {...item} />;
      case "supportfeature":
        return <Support key={index} {...item} />;


      default:
        return null;
    }
  });

  const button = content.map((item, index) => {
    // console.log("item", item);

    switch (item.slice_type) {
      case "new_tariffplan":
        return (
          <div className={style.buttonWrapper}>
            <Button variant={VARIANT.PRIMARY} to={`${item.primary.buttonlink.url}`}>
              <RichText render={item.primary.buttontext.richText} />
            </Button>
          </div>
        )
      default:
        return null;
    }
  });

  return (
    <div className={style.wrapper}>
      <div className={style.container}>{sections}</div>

      <div className={style.comparisonContainer}>
        <div className={style.featureComparisonHeader}>{featureComnparisonHeader}</div>
        <div className={style.featureComparison}>{featureComnparison}</div>
      </div>

      <div className={style.button}>{button}</div>

      <PlansFeatures content={content} activepoint={activepoint} />

      <>{anotherSections}</>

      <FaqSemanticMarkup questions={faqList} />
      <BreadcrumbsSemanticMarkup
        pageTitle={metatitle.text}
        pageUrl={canonical.text}
      />
    </div>
  );
};

PricingPage.propTypes = {
  content: PropTypes.array.isRequired,
  canonical: PropTypes.object.isRequired,
  metatitle: PropTypes.object.isRequired,
};

export default PricingPage;
