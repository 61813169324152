import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Style from './BenefitTable.module.scss';
import { RichText } from 'prismic-reactjs';
import cross from '../../images/cross.svg';
import check from '../../images/check.svg';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const BenefitTable = ({
    primary_feature_heading,
    primary_feature_sub_heading,
    primary_feature_title,
    primary_for_advance_plan,
    primary_for_business_plan,
    primary_for_free_plan,
    primary_for_small_plan,
    primary_laws_for_plan,
    items,
}) => {
    // console.log(primary_laws_for_plan, 'primary_laws_for_plan');

    const truncateText = (text, maxItems = 3) => {
        // console.log(text, 'text');

        const words = text?.split(', ');
        if (words.length <= maxItems) {
            return text;
        } else {
            return `${words.slice(0, maxItems - 1).join(', ')}, ${words[maxItems - 1][0]
                }...`;
        }
    };

    const truncatedText = truncateText(primary_laws_for_plan[0].text);

    const [isDisplay, setIsDisplay] = useState(Style.notToDisplay);
    const [isDisplay1, setIsDisplay1] = useState(Style.notToDisplay);
    const [isDisplay2, setIsDisplay2] = useState(Style.notToDisplay);
    const [isDisplay3, setIsDisplay3] = useState(Style.notToDisplay);

    const handleMouseEnter = () => {
        setIsDisplay(Style.lawsforplanHover);
    };
    const handleMouseLeave = () => {
        setIsDisplay(Style.notToDisplay);
    };

    const handleMouseEnter1 = () => {
        setIsDisplay1(Style.lawsforplanHoverOne);
    };
    const handleMouseLeave1 = () => {
        setIsDisplay1(Style.notToDisplay);
    };

    const handleMouseEnter2 = () => {
        setIsDisplay2(Style.lawsforplanHoverTwo);
    };
    const handleMouseLeave2 = () => {
        setIsDisplay2(Style.notToDisplay);
    };

    const handleMouseEnter3 = () => {
        setIsDisplay3(Style.lawsforplanHoverThree);
    };

    const handleMouseLeave3 = () => {
        setIsDisplay3(Style.notToDisplay);
    };

    return (
        <div className={Style.BenefitTable}>
            <Accordion
                className={Style.accordion}
                allowZeroExpanded
                allowMultipleExpanded
                preExpanded={['a']}
            >
                <AccordionItem className={Style.accordionItem} uuid="a">
                    <AccordionItemHeading className={Style.accordionItemHeading}>
                        <AccordionItemButton className={Style.accordionItemButton}>
                            <div className={Style.heading}>
                                <RichText render={primary_feature_heading} />
                            </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={Style.accordionItemPanel}>
                        <div className={Style.content}>
                            {primary_feature_sub_heading && (
                                <div className={Style.subheadingRow}>
                                    <div className={Style.subheading}>
                                        <RichText render={primary_feature_sub_heading} />
                                    </div>
                                    <div
                                        className={Style.lawsforplan}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <p>{`${truncatedText}`}</p>
                                        <div className={isDisplay}>
                                            <p>{`${primary_laws_for_plan[0].text}`}</p>
                                        </div>
                                    </div>
                                    <div
                                        className={Style.lawsforplan}
                                        onMouseEnter={handleMouseEnter1}
                                        onMouseLeave={handleMouseLeave1}
                                    >
                                        <p>{`${truncatedText}`}</p>
                                        <div className={isDisplay1}>
                                            <p>{`${primary_laws_for_plan[0].text}`}</p>
                                        </div>
                                    </div>
                                    <div
                                        className={Style.lawsforplan}
                                        onMouseEnter={handleMouseEnter2}
                                        onMouseLeave={handleMouseLeave2}
                                    >
                                        <p>{`${truncatedText}`}</p>
                                        <div className={isDisplay2}>
                                            <p>{`${primary_laws_for_plan[0].text}`}</p>
                                        </div>
                                    </div>
                                    <div
                                        className={Style.lawsforplan}
                                        onMouseEnter={handleMouseEnter3}
                                        onMouseLeave={handleMouseLeave3}
                                    >
                                        <p>{`${truncatedText}`}</p>
                                        <div className={isDisplay3}>
                                            <p>{`${primary_laws_for_plan[0].text}`}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {primary_feature_title.length > 0 && (
                                <div className={Style.featureTitleRow}>
                                    <div className={Style.featuretitle}>
                                        <RichText render={primary_feature_title} />
                                    </div>
                                    <div className={Style.lawsforplan}>
                                        <RichText render={primary_for_free_plan} />
                                    </div>
                                    <div className={Style.lawsforplan}>
                                        <RichText render={primary_for_small_plan} />
                                    </div>
                                    <div className={Style.lawsforplan}>
                                        <RichText render={primary_for_business_plan} />
                                    </div>
                                    <div className={Style.lawsforplan}>
                                        <RichText render={primary_for_advance_plan} />
                                    </div>
                                </div>
                            )}
                            {items?.map((item, index) => {
                                return (
                                    <>
                                        {item?.feature_title && (
                                            <div
                                                className={
                                                    index % 2 === 0
                                                        ? Style.featuretitleRow
                                                        : Style.featureTitleRow
                                                }
                                                key={index}
                                            >
                                                <div className={Style.featuretitle}>
                                                    <RichText render={item?.feature_title?.richText} />
                                                </div>
                                                <div className={Style.lawsforplan}>
                                                    {item?.for_free_plan === true ? (
                                                        <img src={`${check}`} />
                                                    ) : (
                                                        <img src={`${cross}`} />
                                                    )}
                                                </div>
                                                <div className={Style.lawsforplan}>
                                                    {item?.for_small_plan === true ? (
                                                        <img src={`${check}`} />
                                                    ) : (
                                                        <img src={`${cross}`} />
                                                    )}
                                                </div>
                                                <div className={Style.lawsforplan}>
                                                    {item?.for_business_plan === true ? (
                                                        <img src={`${check}`} />
                                                    ) : (
                                                        <img src={`${cross}`} />
                                                    )}
                                                </div>
                                                <div className={Style.lawsforplan}>
                                                    {item?.for_advance_plan === true ? (
                                                        <img src={`${check}`} />
                                                    ) : (
                                                        <img src={`${cross}`} />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                );
                            })}
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

BenefitTable.propTypes = {
    primary_feature_heading: PropTypes.array.isRequired,
    primary_feature_sub_heading: PropTypes.array.isRequired,
    primary_feature_title: PropTypes.array.isRequired,
    primary_for_advance_plan: PropTypes.array.isRequired,
    primary_for_business_plan: PropTypes.array.isRequired,
    primary_for_free_plan: PropTypes.array.isRequired,
    primary_for_small_plan: PropTypes.array.isRequired,
    primary_laws_for_plan: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
};

export default BenefitTable;
