import React from 'react';
import PropTypes from 'prop-types';
import BenefitTable from '../BenefitTable';
import styles from './Legislationsupport.module.scss';

const Legislationsupport = ({ primary, items }) => {

    // console.log('items', items);

    // const feature_title = items.filter((item) => item.feature_title);

    // console.log('feature_title', feature_title);




    return (
        <>
            <div className={styles.Legislationsupport}>
                <BenefitTable
                    primary_feature_heading={primary.feature_heading?.richText}
                    primary_feature_sub_heading={primary.feature_sub_heading?.richText}
                    primary_feature_title={primary.feature_title?.richText}
                    primary_for_advance_plan={primary.for_advance_plan?.richText}
                    primary_for_business_plan={primary.for_business_plan?.richText}
                    primary_for_free_plan={primary.for_free_plan?.richText}
                    primary_for_small_plan={primary.for_small_plan?.richText}
                    primary_laws_for_plan={primary.laws_for_plan?.richText}
                    items={items}
                // items_feature_title={item.feature_title?.richText}
                // items_for_free_plan={item.for_free_plan}
                // items_for_small_plan={item.for_small_plan}
                // items_for_business_plan={item.for_business_plan}
                // items_for_advance_plan={item.for_advance_plan}
                />
            </div>
        </>
    );
};

Legislationsupport.propTypes = {
    primary: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
};

export default Legislationsupport;
