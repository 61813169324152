import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import style from './Hero.module.scss';

const Hero = ({ primary, sliceType }) => {
  const { title, subtitle } = primary;

  // console.log('sliceType', sliceType);
  // console.log('primary', primary);
  // console.log('subtitle', subtitle);

  return (
    <div className={style.wrapper}>
      {sliceType === 'hero_business' ? (
        <div className={style.container}>
          <div className={style.title}>
            <RichText render={title.richText} />
          </div>

          {/* <div className={style.subtitle}>
            <RichText render={subtitle.richText} />
          </div> */}
          <div className={style.description}>
            <RichText render={subtitle.richText} />
          </div>
        </div>
      ) : (
        <div className={style.container}>
          {/* <div className={style.description}>
            <RichText render={subtitle.richText} />
          </div> */}
        </div>
      )}
    </div>
  );
};

Hero.propTypes = {
  sliceType: PropTypes.string,
  primary: PropTypes.object.isRequired,
};

export default Hero;
